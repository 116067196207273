import { useState, useEffect } from 'react';

const MOBILE_THRESHOLD = 900;
const SMALL_SCREEN_THRESHHOLD = 1590

export const useViewPort = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(undefined);
  const [isSmallScreen, setSmallScreen] = useState(undefined)

  useEffect(() => {
    if (isMobile === undefined || isSmallScreen === undefined) {
      setIsMobile(window.innerWidth <= MOBILE_THRESHOLD);
      setSmallScreen(window.innerWidth <= SMALL_SCREEN_THRESHHOLD);
    }
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      setIsMobile(window.innerWidth <= MOBILE_THRESHOLD);
      setSmallScreen(window.innerWidth <= SMALL_SCREEN_THRESHHOLD)
    }
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width, isMobile, isSmallScreen };
};