import React, { useEffect, useState } from 'react';
import space from './space.png';
import './style.css';
import Randomstring from 'randomstring';
import image from './spaceship.png'
import Navigation from './Navigation';
import { Projects } from '../Projects/Projects.tsx';
import Contact from '../Contact/Contact';
import { useViewPort } from '../../hooks/useViewPort';

function BannerContent() {
  const { isMobile } = useViewPort();
  const [str1, setStr1] = useState({ text: '', decoded: false });
  const [str2, setStr2] = useState({ text: '', decoded: false });
  const [str3, setStr3] = useState({ text: '', decoded: false });
  const [str4, setStr4] = useState({ text: '', decoded: false });
  const [str5, setStr5] = useState({ text: '', decoded: false });
  const [str6, setStr6] = useState({ text: '', decoded: false });
  const [disclaimerClass, setDisclaimerClass] = useState('Hidden')
  const [buttonClass, setButtonClass] = useState('Hidden')
  const timeConstant = isMobile ? 8 : 3
  const scrollOffset = 100

  const time1 = 2000 / timeConstant
  const time2 = 2350 / timeConstant
  const time3 = 2700 / timeConstant
  const time4 = 3300 / timeConstant
  const time5 = 3900 / timeConstant
  const time6 = 4500 / timeConstant
  const time7 = 5000 / timeConstant

  useEffect(() => {
    const interval = setInterval(() => setStr1({ text: Randomstring.generate(3), decoded: false }), 200);
    const interval2 = setInterval(() => setStr2({ text: Randomstring.generate(3), decoded: false }), 150);
    const interval3 = setInterval(() => setStr3({ text: Randomstring.generate(3), decoded: false }), 250);
    const interval4 = setInterval(() => setStr4({ text: Randomstring.generate(3), decoded: false }), 400);
    const interval5 = setInterval(() => setStr5({ text: Randomstring.generate(3), decoded: false }), 100);
    const interval6 = setInterval(() => setStr6({ text: Randomstring.generate(3), decoded: false }), 70);

    setTimeout(() => {
      setStr1({ text: 'Ed', decoded: true });
      // setStr1({ text: 'Sof', decoded: true });
      clearInterval(interval);
    }, time5);
    setTimeout(() => {
      setStr2({ text: 'win', decoded: true });
      // setStr2({ text: 'twa', decoded: true });
      clearInterval(interval2);
    }, time1);
    setTimeout(() => {
      setStr3({ text: '\'s', decoded: true });
      // setStr3({ text: 're ', decoded: true });
      clearInterval(interval3);
    }, time3);
    setTimeout(() => {
      setStr4({ text: 'Por', decoded: true });
      // setStr4({ text: 'Dev', decoded: true });
      clearInterval(interval4);
    }, time2);
    setTimeout(() => {
      setStr5({ text: 'tfo', decoded: true });
      // setStr5({ text: 'elo', decoded: true });
      clearInterval(interval5);
    }, time6);
    setTimeout(() => {
      setStr6({ text: 'lio', decoded: true });
      // setStr6({ text: 'per', decoded: true });
      clearInterval(interval6);
    }, time4);
    setTimeout(() => {
      setDisclaimerClass('Home-Disclaimer Fade-In')
      setButtonClass('CTA-Button Fade-In')
    }, time7);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
      clearInterval(interval3);
      clearInterval(interval4);
      clearInterval(interval5);
      clearInterval(interval6);
    };
  }, []);

  return (
    <div className="Content-Section Flex-Row" id="first">
      <section className="Content-Subsection Flex-Row J-C" >
        <section className="Flex-Row J-S-B A-C Home-Section ">
          <div className="Flex-Column J-F-S">
            <div style={{ justifyContent: 'space-between', flexDirection: 'row', width: '50%', display: 'flex' }}>
              <div className='Flex-Row'>
                <h1 className={`Home-Title Shadow-Text${str1.decoded ? '-Alt' : ''}`}>{str1.text}</h1>
                <h1 className={`Home-Title Shadow-Text${str2.decoded ? '-Alt' : ''}`}>{str2.text}</h1>
                <h1 className={`Home-Title Shadow-Text${str3.decoded ? '-Alt' : ''}`}>{str3.text}</h1>
              </div>
            </div>
            <div style={{ justifyContent: 'space-between', flexDirection: 'row', width: '50%', display: 'flex' }}>
              <div className='Flex-Row'>
                <h1 className={`Home-Title Shadow-Text${str4.decoded ? '-Alt' : ''}`}>{str4.text}</h1>
                <h1 className={`Home-Title Shadow-Text${str5.decoded ? '-Alt' : ''}`}>{str5.text}</h1>
                <h1 className={`Home-Title Shadow-Text${str6.decoded ? '-Alt' : ''}`}>{str6.text}</h1>
              </div>
            </div>
            <br />
            <p className={disclaimerClass} id="scroll-point">A showcase of my latest projects where I bridged brilliant ideas to great technology </p>
            <button className={buttonClass} onClick={() => {
              const worksNodes = document.getElementById('works')
              const scrollToY = worksNodes.getBoundingClientRect().top
              window.scrollTo({ top: scrollToY - scrollOffset, behavior: 'smooth' })
            }}>
              Go To Projects
            </button>
          </div>
          <div className="Home-Image-Container Flex-Column">
            <img className="Home-Image-One" alt="spaceship-one" src={image} style={{ height: '180px', opacity: '0.7' }} />
            {!isMobile &&
              <img className="Home-Image-Two" alt="spaceship-two" src={image} style={{ height: '120px', opacity: '0.3' }} />
            }
          </div>
        </section>
      </section>
    </div>
  );
}

function App() {
  return (
    <>
      <div className="Background-Image">
        <img
          className="Home-Image-Three"
          alt='Black-Hole' src={space}
          style={{ height: '500px', opacity: 1 }}
        />
      </div>
      <div className="Body-Body Flex-Column J-S-B A-C">
        <section className="Flex-Column J-F-S A-C" style={{ overflowX: 'clip' }}>
          <Navigation />
          <BannerContent />
          <Projects />
          <Contact />
        </section>
      </div>
    </>
  );
}

export default App;