import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { AiOutlineClose } from "react-icons/ai";
import logo from "./smallMe.png";
import "./style.css";

const color = "purple";

const lineColors = {
  border: `1px solid ${color}`,
  backgroundColor: "white",
  boxShadow: "0 0 5px red, 0 0 10px blue, 0 0 15px blue",
};

export const MobileMenu = ({ handleClick, view }) => {
  return (
    <section className="Mobile-Menu-Container Shift-Top">
      <div className="Flex-Column A-C Mobile-Menu-Top J-S-A">
        <div className="Flex-Column A-C J-C Full-Width">
          <AiOutlineClose onClick={handleClick} className={"Menu-Close"} />
        </div>
        <div className="Line-Mobile" style={lineColors} />
        <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item">
          <AnchorLink
            offset={150}
            className="Nav-Label"
            href="#first"
            onClick={() => handleClick(0)}
          >
            Space
          </AnchorLink>
        </div>
        <div className="Line-Mobile" style={lineColors} />
        <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item">
          <AnchorLink offset={100} className="Nav-Label" href="#works" onClick={handleClick}>
            My Works
          </AnchorLink>
        </div>
        <div className="Line-Mobile" style={lineColors} />
        <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item">
          <AnchorLink
            className="Nav-Label"
            href="#contact"
            onClick={handleClick}
          >
            Contact & CV
          </AnchorLink>
        </div>
        <div className="Line-Mobile" style={lineColors} />
        {/* <a className="Nav-Link-Mobile" href="mailto:e.aoraha33@gmail.com" onClick={handleClick}>Email Me</a>
        <a className="Nav-Link-Mobile" href={resume} onClick={handleClick} download>Download CV</a> */}
      </div>
      <div className="Mobile-Menu-Bottom" />
    </section>
  );
};
