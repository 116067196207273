import './App.css';
import Space from './components/Space/Space.js'

window.history.scrollRestoration = 'manual'

function App() {
  return (
    <div className='Fade-In'>
      <Space />
    </div>
  );
}

export default App;
