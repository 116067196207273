import angular from './badges/angular.png'
// import expo from './badges/expo.png'
import mongo from './badges/mongo.png'
import node from './badges/node.png'
import python from './badges/python.png'
import react from './badges/react.png'
import ruby from './badges/ruby.png'
import spring from './badges/spring.png'
import typescript from './badges/typescript.png'
// G-Force
import gforce from './imgs/gforce.png'
// Tyduu
import results from './imgs/tyduu_results.png'
import survey from './imgs/tyduu_survey.png'
// import web from './imgs/tyduu_web.jpeg'
// Ford Credit 
import credit from './imgs/credit.png'
// BoozApp
import boozapp1 from './imgs/boozapp1.png'
import boozapp2 from './imgs/boozapp2.png'
// PSS
import traps from './imgs/traps.png';
import scruff from './imgs/scruff.png';
import jackd2 from './imgs/jackd2.png';
// Peerspace
import PS1 from './imgs/PS1.png'
import PS2 from './imgs/PS2.png'
import PS3 from './imgs/PS3.png'




export const projects = [
  {
    company: 'BoozApp',
    role: 'Co-Founder, CTO',
    description: 'As CTO of BoozApp, I conceptualized, built, and maintained all technical aspects of the app. Focusing on user feedback, UX, and low-to-no cost solutions, our team grew a digital community with over 100,000 sign ups and 30,000 monthly active users. I am proud to have profitably sold BoozApp and its rights to a liquor marketplace app in 2023.',
    projectDescription: 'Using BoozApp, you can look up the average MSRP price, shelf price, and vote on a Fair Price for any bottle. You can also upload your own bottles at home to comapre how much you paid vs. their estimated value.',
    tech: [
      { img: react, alt: 'React and React Native' },
      { img: python, alt: 'Python Django' },
      { img: typescript, alt: 'Typescript' }
    ],
    links: ['https://www.booz.app/'],
    year: '2019-2023',
    image: [boozapp1, boozapp2],
    // color: 'rgb(199, 141, 63)',
    color: 'rgb(224,193,136)',
    project: 'BoozApp - Mobile App',
    acquired: true
  },
  {
    company: 'Peerspace',
    role: 'Senior Mobile Engineer',
    description: 'Peerspace is an event space rental app. My role was focused primarily on improving the app\'s performance, architecture, maintainability, and user-experience. I also played a major role in the app\'s product development and worked closely with the design team for a seemless mobile experience.',
    projectDescription: 'The Peerspace app offers a rich search tool to find the perfect space for your event. You can book a space, host your own space, manage your upcoming events, sync your calendar, and much more from the app.',
    tech: [
      { img: react, alt: 'React and React Native' },
      { img: typescript, alt: 'Typescript' }
    ],
    links: ['https://www.peerspace.com/'],
    year: '2022-present',
    image: [PS1, PS2, PS3],
    color: 'rgb(134,89,236)',
    project: 'Peerspace Mobile App'
  },
  {
    company: 'Perry Street Software',
    role: 'Software Developer',
    description: 'Perry Street is one of the largest LGBT+ companies today with over 25 million worldwide users and multiple brands. My role was centered around building hybrid React Native apps that could quickly be launched in our native apps. I was also responsible for maintaining all brand websites and internal web tools.',
    projectDescription: 'On the labs beta team, we released a content creation feed feature where users could upload images and videos into a tik-tok-like feed. The feature was a hit and increased monthly engagement KPIs by 10-20% consistently.',
    tech: [{ img: react, alt: 'React and React Native' }, { img: ruby, alt: 'Ruby Sinatra' }, { img: angular, alt: 'Angular' }, { img: typescript, alt: 'Typescript' }],
    links: ['https://www.perrystreet.com/', 'https://www.scruff.com/', 'https://www.jackd.com/'],
    year: '2019 - 2022',
    image: [traps, scruff, jackd2],
    color: 'rgb(93,121,141)',
    // color: '#36454F',
    project: 'Labs Beta Stories & Websites'
  },
  {
    company: 'Tyduu',
    role: 'Software Lead',
    description: 'Tyduu was a Detroit startup looking to help small mom n\' pop shops get customer feedback via mobile text surveys.',
    projectDescription: 'By texting a keyword to a short number, you would receive a survey by SMS. As a shop owner, you could then see all the analyzed results on your dashboard.',
    tech: [{ img: angular, alt: 'Angular' }, { img: react, alt: 'React' }, { img: mongo, alt: 'Mongo' }, { img: node, alt: 'Node' }],
    links: [],
    year: 2018,
    image: [results, survey],
    color: 'rgb(29,194,166)',
    project: 'Tyduu Mobile Survey'
  },
  {
    company: 'Ford Motor Company',
    role: 'Software Consultant',
    description: 'I joined Ford Credit and the Ford GeForce team as a consultant. My role had 2 parts: transform Ford\'s software delivery process and work with developers to build new features following software craftsmanship principles.',
    projectDescription: 'On the GeForce team I worked on the backend code that managed vehicle configuration and packages. At Ford Credit, I helped build an account manager platform for Ford\'s European customers.',
    tech: [{
      img: angular,
      alt: 'Angular'
    }, {
      img: spring,
      alt: 'Java Spring Boot'
    }],
    links: [],
    year: 2017,
    image: [gforce, credit],
    project: 'EU Account Manager & Vehicle Configuration Packages',
    color: 'rgb(115, 178, 233)'
    // color: 'rgb(39,104,161)'
  },
]