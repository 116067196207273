import React from "react";
import { projects } from "./projects";
import { ProjectTemplate } from "./ProjectTemplate.tsx";
import { useViewPort } from '../../hooks/useViewPort'
import "./style.css";

export const Projects = () => {
  const {isMobile } = useViewPort()
  
  return (
    <div className="Flex-Column Projects-Section" id="works">
      {projects.map((data, index) => 
        <ProjectTemplate
          key={data.company}
          {...data}
          reverse={false}
        />
      )}
    </div>
  );
};
