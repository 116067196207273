import React from "react";
import { useViewPort } from "../../hooks/useViewPort.js";
import "./style.css";

export const ProjectTemplate = ({
  year,
  company,
  description,
  role,
  tech,
  links,
  reverse,
  image,
  color,
  project,
  projectDescription,
  acquired,
}: {
  year: number;
  company: string;
  description: string;
  tech: string[];
  links: string[];
  reverse: boolean;
  image: string[];
  role: string;
  color: string;
  project: string;
  projectDescription: string;
  acquired: boolean;
}) => {
  const { isSmallScreen } = useViewPort();
  return (
    <div
      className={`${isSmallScreen ? "Flex-Column" : "Flex-Row"} Project-Row ${
        reverse && "Reverse"
      }`}
    >
      <section className="Project-Year-Section Flex-Column">
        <p
          style={{
            color: color || undefined,
            marginBottom: acquired ? "0px" : "12px",
            paddingLeft: "10px",
          }}
        >
          {company}
        </p>
        {acquired && (
          <p
            style={{
              color,
              fontSize: "20px",
              paddingLeft: "10px",
              margin: "10px 0",
            }}
          >
            (Acquired 2023)
          </p>
        )}
        <span
          className="Project-Year-Text"
          style={{
            backgroundColor: color,
          }}
        >
          {year}
        </span>
        {/* <span>{year}</span> */}
        {links.length > 0 && (
          <section className="Links-Section Flex-Column" style={{ color }}>
            {/* <p>Links: </p> */}
            {links.map((url) => (
              <a key={url} href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
            ))}
          </section>
        )}

        <section className="Project-Description-Left">
          {/* <h1 style={{ color }}>{project}</h1> */}
          <h1 style={{ color }}>{role}</h1>
          <p>{description}</p>
          {isSmallScreen && <p>{projectDescription}</p>}
        </section>
        {/* <p>Frameworks used</p> */}
        <section className="Tech-Stack-Section Flex-Row">
          {tech.map((badge) => (
            <img
              key={badge.alt}
              alt={badge.alt}
              src={badge.img}
              style={{
                objectFit: "contain",
                height: "45px",
                marginRight: "10px",
              }}
            />
          ))}
        </section>
      </section>
      {!isSmallScreen && (
        <section
          className="Projects-Divider"
          style={{
            borderColor: color,
          }}
        />
      )}
      <section className="Projects-Description">
        <h1 style={{ color, fontSize: "1.7rem" }}>{project}</h1>
        {!isSmallScreen && <p className="Readable">{projectDescription}</p>}
        {/* <p>Role: {role}</p>
        <p>{description}</p> */}
        {/* <section className="Tech-Stack-Section Flex-Row">
          {tech.map((badge) => (
            <img
              alt={badge.alt}
              src={badge.img}
              style={{ objectFit: "contain", height: "45px", margin: "0 10px" }}
            />
          ))}
        </section> */}
        <section className="Project-Image-Section">
          {image.length > 0 &&
            image.map((img) => {
              return (
                <img
                  key={img}
                  src={img}
                  alt="Project Showcase"
                  className="Project-Image"
                />
              );
            })}
        </section>
      </section>
    </div>
  );
};
