import React, { useEffect, useState } from 'react';
// import { setView } from './NavigateSlice';
// import useViewport from '../../utils/useViewport';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import Hamburger from 'hamburger-react';
import './style.css';
import { useViewPort } from '../../hooks/useViewPort';
import { IoMenu } from 'react-icons/io5';
import { MobileMenu } from '../Banner/MobileMenu.tsx';

function Navigation() {
  const [isOpen, setOpen] = useState(false);
  const [view, setView] = useState(0)
  const { width, isMobile } = useViewPort();
  const [bg, setBg] = useState({ show: false, style: {} })
  const [scrollBreakPoint, set1] = useState()
  const [scrollLinePoint, set2] = useState()

  const handleClick = (view) => {
    setView(view)
    setTimeout(() => {
      setOpen(!isOpen);
    }, 300)
  };

  const color = 'purple';

  const lineColors = {
    border: `1px solid ${color}`,
    backgroundColor: 'white',
    boxShadow: '0 0 5px red, 0 0 10px blue, 0 0 15px blue'
  };

  const shadow = {
    textShadow: '0 0 5px red, 0 0 10px blue, 0 0 15px blue',
    color: 'white'
  };

  useEffect(() => {
    const scrollBreakPointElement = document.getElementById('scroll-point')
    set1(scrollBreakPointElement.getBoundingClientRect().y)

    const scrollLineEle = document.getElementById('works')
    set2(scrollLineEle.getBoundingClientRect().y - 250)
  }, [])

  useEffect(() => {
    const fn = e => {
      if ((window.innerHeight + Math.round(window.scrollY)) + 250 >= document.body.offsetHeight) {
        if (view !== 2) {
          setView(2)
        }
      } else if (window.scrollY >= scrollLinePoint) {
        if (view !== 1) {
          setView(1)
        }
      } else if (window.scrollY < scrollLinePoint) {
        if (view !== 0) {
          setView(0)
        }
      }

      if (window.scrollY >= scrollBreakPoint && !bg.show) {
        setBg({ show: true, style: { backgroundColor: 'rgba(0,0,0,0.9)' } })
      } else if (window.scrollY < scrollBreakPoint && bg.show) {
        setBg({ show: false, style: {} })
      }


    }
    window.addEventListener('scroll', fn)

    return () => window.removeEventListener('scroll', fn)
  }, [bg, view, scrollBreakPoint, scrollLinePoint, isMobile])


  return (
    <section className="Navigation-Section Flex-Row J-S-A A-C" style={bg.style}>
      <h1 className="Fade-In">
        <span style={{ textShadow: '0 0 5px red, 0 0 10px blue, 0 0 15px blue', fontSize: '1.2rem' }}>EDWIN</span>
        <span style={{ textShadow: '0 0 5px red, 0 0 10px blue, 0 0 15px blue', fontSize: '1.2rem' }}> | </span>
        <span style={{ textShadow: '0 0 5px green', fontSize: '1.2rem' }}>AORAHA</span>
      </h1>
      {width > 800 ?
        <section className="Flex-Row J-S-A A-F-S Nav-Buttons Fade-In">
          <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item" >
            <AnchorLink href="#first" offset={150} className="Nav-Label" style={view === 0 ? shadow : {}}>SPACE</AnchorLink>
            <div className={`${view === 0 ? 'Line' : ''}`} style={view === 0 ? lineColors : {}}></div>
          </div>
          <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item" >
            <AnchorLink href="#works" offset={100} className="Nav-Label" style={view === 1 ? shadow : {}}>MY WORKS</AnchorLink>
            <div className={`${view == 1 ? 'Line' : ''}`} style={view === 1 ? lineColors : {}}></div>
          </div>
          <div className="Flex-Column A-F-S Cursor-Pointer Nav-Item" >
            <AnchorLink href="#contact" className="Nav-Label" style={view === 2 ? shadow : {}}>CONTACT & CV</AnchorLink>
            <div className={`${view == 2 ? 'Line' : ''}`} style={view === 2 ? lineColors : {}}></div>
          </div>
        </section> :
        (
          <IoMenu size={40} color={'white'} onClick={() => setOpen(true)}/>
        )
      }
      {isOpen && <MobileMenu view={view} setView={setView} handleClick={() => handleClick()} />}
    </section>
  );
}

export default Navigation;