import React, { useEffect, useState } from 'react';
import me from './me2.png'
import './style.css';
import { useViewPort } from '../../hooks/useViewPort';
import emailjs from 'emailjs-com'
import resume from './EdwinAorahaResume2023.pdf'

function Contact() {
  const { isMobile } = useViewPort()
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [replyTo, setReplyTo] = useState('');
  const [sending, setSending] = useState('SEND');


  const handleClick = () => {
    if (!name || !message || !replyTo) {
      return setSending('ERROR')
    }
    emailjs.init('YUCnFseRGTJfx1yD7')
    setSending('SENDING')
    emailjs.send(
      'default_service',
      'template_cv5atku',
      {
        name,
        message,
        reply_to: replyTo
      }
    ).then(() => {
      setName('');
      setMessage('');
      setReplyTo('');
      setSending('SENT');
    }).catch((error) => setSending('Error'))
  }

  useEffect(() => {
    setSending('SEND')
  }, [name, message, replyTo])

  return (
    <section className="Flex-Row Contact-Form" id="contact">
      <div className='Footer-Left'>
        <p className="Contact-Text Shadow-Text-Alt" style={{ alignSelf: 'center' }}>Contact & CV</p>
        <span className="Input-Group Flex-Column">
          <label className="Contact-Label" htmlFor="name">Name</label>
          <input id="name" value={name} onChange={e => setName(e.target.value)} />
        </span>
        <span className="Input-Group Flex-Column">
          <label className="Contact-Label" htmlFor="email">Email</label>
          <input id="email" value={replyTo} onChange={e => setReplyTo(e.target.value)} />
        </span>
        <span className="Input-Group Flex-Column">
          <label className="Contact-Label" htmlFor="message">Message</label>
          <textarea id="message" value={message} onChange={e => {
            if (e.target.value.length <= 500) {
              setMessage(e.target.value)
            }
          }}></textarea>
        </span>
        <section className={`Button-Group Flex-Row J-S-B ${isMobile ? 'Flex-Column' : 'Flex-Row'}`}>
          <a href={resume} download style={{width: '100%'}}>
            <button className='CTA-Button Inverse'>DOWNLOAD CV</button>
          </a>
          <button className='CTA-Button' onClick={handleClick}>{sending}</button>
        </section>
      </div>
      {!isMobile &&
        <div style={{ flex: 1 }} className={'Flex-Row J-C A-F-E'}>
          <img src={me} alt={'Me'} />
        </div>
      }
    </section>
  );
}

export default Contact;